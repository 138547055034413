<template>
  <v-app>
    <toolbar></toolbar>
    <div
      class="fixed-bottom-right"
      @click="toggleChat"
    >
    <v-avatar>
      <img src="@/assets/bot.png" alt="">
    </v-avatar>
    </div>
    <v-dialog v-model="isChatOpen" persistent max-width="400px">
      <v-card class="chat-card">
        <!-- Cabecera del chat -->
        <v-card-actions class="chat-header">
          Ficenca Chat
          <v-spacer></v-spacer>
          <v-btn icon @click="toggleChat" color="white">
            X
          </v-btn>
        </v-card-actions>

        <!-- Mensajes -->
        <v-card-text class="chat-body">
          <div v-for="message in messages" :key="message.id" class="message">
            <div
              :class="[ 
                'bubble', 
                message.sender === 'bot' ? 'bot-bubble' : 'user-bubble',
              ]"
              v-html="message.text"
            ></div>
          </div>
        </v-card-text>

        <!-- Botones para opciones -->
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn 
            text 
            style="font-weight: 700; font-size:12px; color:white; text-transform:none; letter-spacing:0; background: #005deb;" 
            rounded 
            @click="selectOption('Ubicación')"
          >
            Ver Ubicación
          </v-btn>
          <v-btn 
            text 
            style="font-weight: 700; font-size:12px; color:white; text-transform:none; letter-spacing:0; background: #005deb;" 
            rounded 
            @click="selectOption('Contacto')"
          >
            Ver Contacto
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-main style="padding-bottom:50px;">
      <router-view />
    </v-main>
    <!-- Footer -->
    <v-card flat color="#eeeeee">
      <v-card-actions style="padding:20px 20px">
        <img width="150" src="https://grupoficenca.com/img/logo.17c9b543.png" />
        <p style="font-weight:700;font-size:12px;padding:20px 0 0 20px">
          Ficenca Costa Rica©
        </p>
        <v-spacer></v-spacer>
        <img width="100" src="@/assets/logofiac.png" />
        <img width="100" src="@/assets/michelin.png" />
      </v-card-actions>
    </v-card>
    <div style="width:100%;background:#eeeeee;padding: 0 0 20px 20px">
      <v-btn
        icon
        text
        small
        href="mailto:info@grupoficenca.com"
        style="font-size:14px;"
      >
        <i class="fas fa-envelope"></i>
      </v-btn>

      <v-btn
        icon
        text
        small
        href="https://goo.gl/maps/DddbeYdvZkqiDzXf8"
        target="_blank"
        style="font-size:14px;"
      >
        <i class="fas fa-location-arrow"></i>
      </v-btn>

      <v-btn
        icon
        text
        target="_blank"
        small
        style="font-size:14px;"
        :href="`https://facebook.com/ficencacostarica`"
      >
        <strong><i class="fab fa-facebook-f"></i></strong>
      </v-btn>
      <v-btn
        icon
        text
        target="_blank"
        small
        style="font-size:14px;"
        :href="`https://instagram.com/ficencacostarica`"
      >
        <strong><i class="fab fa-instagram"></i></strong>
      </v-btn>
      <v-btn
        icon
        text
        target="_blank"
        small
        style="font-size:14px;"
        :href="`https://www.linkedin.com/company/ficencacr/`"
      >
        <i class="fab fa-linkedin-in"></i>
      </v-btn>
      <v-btn
        icon
        text
        target="_blank"
        small
        style="font-size:14px;"
        :href="`https://api.whatsapp.com/send?phone=50670188845`"
      >
        <strong><i class="fab fa-whatsapp"></i></strong>
      </v-btn>
    </div>
    <div class="ddff">
      <div
        style="cursor:pointer;font-size:8px;text-align:center;color:white"
        @click="irCreador()"
      >
        Brings | Developer
      </div>
    </div>
  </v-app>
</template>

<script>
import toolbar from './components/toolbar.vue'
export default {
  name: 'App',

  data: () => ({
    isChatOpen: false,
      userMessage: '',
      messages: [
        { id: 1, sender: 'bot', 
        text: '¡Hola! Bienvenido al chat de Ficenca. ¿En qué podemos ayudarte?' }
      ],
    //
  }),
  methods:{
    irCreador(){
      window.open("https://api.whatsapp.com/send?phone=584127787228&text=Hola,%20me%20interesa%20crear%20mi%20marca", "_blank")
    },
    toggleChat() {
      this.isChatOpen = !this.isChatOpen;
    },
    selectOption(option) {
      // Agregar mensaje del usuario
      this.messages.push({
        id: Date.now(),
        sender: 'user',
        text: option,
      });

      // Responder basado en la opción seleccionada
      this.replyToOption(option);
    },
    replyToOption(option) {
      let reply = '';
      if (option === 'Ubicación') {
        reply = 'Estamos ubicados en bodega # 2 Av. 55, Vuelta del Virilla, Uruca, San José';
      } else if (option === 'Contacto') {
        reply = `Puedes contactarnos por:<br>
        <strong>- Administrativo:</strong><br> (506) 2231-0010<br>(506) 2265-2888<br><br>
        <strong>- Departamento de Ventas:</strong><br> (506) 8995-6226<br>(506)  8731-5650<br><br>
        <strong>- Servicio Técnico:</strong><br> (506) 7174-7038`;
      }

      // Simular una pequeña demora para la respuesta del bot
      setTimeout(() => {
        this.messages.push({
          id: Date.now(),
          sender: 'bot',
          text: reply, // Ahora renderizado con v-html
        });
      }, 1000);
    }
  },
  components:{
    toolbar
  }
};
</script>

<style scoped>
/* Altura fija y desbordamiento interno */
.chat-card {
  height: 500px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  overflow: hidden;
}


.chat-header {
  background-color: #005deb;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.chat-body {
  background-color: #f5f5f5;
  flex: 1;
  overflow-y: auto; /* Permitir desplazamiento solo aquí */
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.message {
  margin: 8px 0;
}

.bubble {
  max-width: 75%;
  padding: 10px;
  border-radius: 12px;
  line-height: 1.5;
  word-wrap: break-word;
}

.bot-bubble {
  background-color: #e0e0e0;
  align-self: flex-start;
}

.user-bubble {
  background-color: #005deb;
  color: white;
  align-self: flex-end;
}

.fixed-bottom-right {
  position: fixed;
  bottom: 16px;
  text-transform: none;
  font-weight: 700;
  letter-spacing: 0;
  cursor: pointer;
  right: 16px;
  z-index: 10000;
}

.ddff {
  background: #165198;
  padding: 10px 0;
}

* {
  font-family: "Montserrat";
}

*::selection {
  background: #165198;
  color: #fff;
}
</style>